/* Timeline */
.timeline-message-item {
	margin-top: 25px;
	margin-bottom: 25px;
	margin-left: 25px;
	position: relative;
}
.timeline-message-item .message-inner {
	background: #f7f9fa;
	border: 1px solid #eff2f3;
	border-radius: 3px;
	padding: 10px;
	position: relative;
}
.timeline-message-item .message-inner:before {
	border-right: 5px solid #eff2f3;
	border-style: solid;
	border-width: 5px;
	color: rgba(0,0,0,0);
	content: "";
	display: block;
	height: 0;
	position: absolute;
	left: -10px;
	top: 10px;
	width: 0;
}
.timeline-message-item .message-inner:after {
	border-right: 5px solid #f7f9fa;
	border-style: solid;
	border-width: 5px;
	color: rgba(0,0,0,0);
	content: "";
	display: block;
	height: 0;
	position: absolute;
	left: -9px;
	top: 10px;
	width: 0;
}
.timeline-message-item:before {
	background: #eff2f3;
	border-radius: 2px;
	bottom: -28px;
	content: "";
	height: 100%;
	left: -14px;
	position: absolute;
	width: 1px;
}
.timeline-message-item:after {
	background: #fff;
	border: 1px solid #2e9df7;
	border-radius: 50%;
	content: "";
	height: 11px;
	left: -19px;
	position: absolute;
	top: 10px;
	width: 11px;
}
.clearfix:before, .clearfix:after {
	content: " ";
	display: table;
}
.timeline-message-item .message-head {
	border-bottom: 1px solid #eee;
	margin-bottom: 8px;
	padding-bottom: 8px;
}
.timeline-message-item .message-head .message-icon {
	background-color: #8560a8;
	padding: 10px 12px;
	margin-right: 10px;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.timeline-message-item .message-head .message-icon a i{
	color: #fff;
	font-size: 1.3em;
}
.timeline-message-item .message-head .user-detail {
	overflow: hidden;
}
.timeline-message-item .message-head .user-detail h5 {
	font-size: 1.2em;
	color: #585b5e;
	margin: 0;
	margin-bottom: 2px;
}
.timeline-message-item .message-head .post-type >p {
	color: #8560a8;
	font-weight: bold;
	text-transform: uppercase;
	font-size: .7em;
	margin: 0;
	line-height: 17px;
}
.timeline-message-item .message-head .post-time {
	float: left;
}
.timeline-message-item .message-head .post-time >p {
	color: #858b8f;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: .6em;
	line-height: 15px;
	margin: 0;
}
.timeline-message-item .qa-message-content p {
	text-align: left;
	color: #585b5e;
	font-size: .9em;
	margin: 0;
}

.timeline-created .message-head .message-icon { background-color: #d7e58f; }
.timeline-created .message-head .message-icon .fa { color: #333; }
.timeline-assign .message-head .message-icon { background-color: #554676; }
.timeline-notes .message-head .message-icon { background-color: #05421a; }
.timeline-file .message-head .message-icon { background-color: #134648; }
.timeline-contract .message-head .message-icon { background-color: #1d588a; }
.timeline-email .message-head .message-icon { background-color: #7d871e; }
.timeline-call .message-head .message-icon { background-color: #a5124d; }
.timeline-meeting .message-head .message-icon { background-color: #f1ca23; }
.timeline-meeting .message-head .message-icon .fa { color: #333; }
.timeline-councelor .message-head .message-icon { background-color: #c34e01; }
.timeline-info .message-head .message-icon { background-color: #2b82be; }
/* /Timeline */

/* Comment Box */
.widget-area {
	background-color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	float: left;
	margin-top: 30px;
	padding: 25px 30px;
	position: relative;
	width: 100%;
}
.status-upload {
	background: none repeat scroll 0 0 #f5f5f5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	float: left;
	width: 100%;
}
.status-upload form {
	float: left;
	width: 100%;
}
.status-upload form textarea {
	background: none repeat scroll 0 0 #fff;
	border: medium none;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	-ms-border-radius: 4px 4px 0 0;
	-o-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
	color: #777777;
	float: left;
	font-family: Lato;
	font-size: 14px;
	height: 142px;
	letter-spacing: 0.3px;
	padding: 20px;
	width: 100%;
	resize:vertical;
	outline:none;
	border: 1px solid #F2F2F2;
}

.status-upload ul {
	float: left;
	list-style: none outside none;
	margin: 0;
	padding: 0 0 0 15px;
	width: auto;
}
.status-upload ul > li {
	float: left;
}
.status-upload ul > li > a {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	color: #777777;
	float: left;
	font-size: 14px;
	height: 30px;
	line-height: 30px;
	margin: 10px 0 10px 10px;
	text-align: center;
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-ms-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 30px;
	cursor: pointer;
}
.status-upload ul > li > a:hover {
	background: none repeat scroll 0 0 #606060;
	color: #fff;
}
.status-upload form button {
	border: medium none;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	color: #fff;
	float: right;
	font-family: Lato;
	font-size: 14px;
	letter-spacing: 0.3px;
	margin-right: 9px;
	margin-top: 9px;
	padding: 6px 15px;
}
.dropdown > a > span.green:before {
	border-left-color: #2dcb73;
}
.status-upload form button > i {
	margin-right: 7px;
}
/* /Comment Box */

/* Form Wizard */
.bs-wizard {border-bottom: solid 1px #e0e0e0; padding: 0 0 10px 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 16px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 14px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: #fbe8aa; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #fbbd19; border-radius: 50px; position: absolute; top: 8px; left: 8px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 20px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
.bs-wizard {margin-top: 40px; border-bottom: 0; margin-bottom: 30px;}
/* /Form Wizard */