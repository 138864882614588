// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

.panel-rocket .panel-heading h3.panel-title {
	display: block;
	float: left;
	line-height: 30px;
}

.social_links a {
	font-size: 20px;
	padding: 0 5px;
	color: grey;
}	

.fa-btn {
    margin-right: 6px;
}

table a:not(.btn), .table a:not(.btn) {
	text-decoration: none;
}


/* Panel Table */
.panel-table .panel-body{
    padding:0;
}

.panel-table .panel-body .table-bordered{
    border-style: none;
    margin:0;
}

.panel-table .panel-body .table-bordered > thead > tr > th:first-of-type {
    text-align:center;
    width: 100px;
}

.panel-table .panel-body .table-bordered > thead > tr > th:last-of-type,
.panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    border-right: 0px;
}

.panel-table .panel-body .table-bordered > thead > tr > th:first-of-type,
.panel-table .panel-body .table-bordered > tbody > tr > td:first-of-type {
    border-left: 0px;
}

.panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
    border-bottom: 0px;
}

.panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
    border-top: 0px;
}

.panel-table .panel-footer .pagination{
    margin:0; 
}

/*
used to vertically center elements, may need modification if you're not using default sizes.
*/
.panel-table .panel-footer .col{
    line-height: 34px;
    height: 34px;
}

.panel-table .panel-heading .col h3{
    line-height: 30px;
    height: 30px;
}

.panel-table .panel-body .table-bordered > tbody > tr > td{
    line-height: 34px;
}
/* /Panel Table */

/* Chosen Package Modifications */
.chosen-choices {
    @include border-radius(3px);
    padding: 2.5px 10px!important;
    border: 1px solid #ccc!important;
}

.chosen-container-single .chosen-single {
    height: 34px;
    padding: 4.5px 0 4.5px 8px;
}

.chosen-container-single .chosen-single div b {
    background-position: 0 7px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 7px;
}

.room-section {
  margin: 20px 0;
  padding: 10px;
  border: 1px dotted black;
  position: relative;
}

.delete-room {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 2;
}
/* /Chosen Package Modifications */
